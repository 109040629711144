.container {
    padding: 0px 10px 10px 10px;
    width: 100%;
    height: 100%;
    overflow: scroll;
   
}

.steps {
    flex-wrap: wrap;
}

.step-item {
    max-width: inherit;
    margin-bottom: 15px;
   
}

.application-status{
    margin-left: 20px;
    font-weight: bold;
    line-height: 25px;
}

.application-name {
    font-size: 25px;
    color: #8c8c8c
}