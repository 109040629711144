.logo {
    height:80px;
    margin: 16px;
   
}

.siteLayout {
    height: 100vh;
    overflow: hidden;
}

.header {
    background: #FFF;
    padding-left: 20px;
}

.contentContainer {
    margin: 10px 15px;
    padding: 10px 20px;
    min-height: 280;
    height:100%;
    background-color: #FFF;
}